

h1 {
  letter-spacing: 0.6px;
}
h2 {
  letter-spacing: 0;
}
body {
    font-family: Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3, Noto Sans JP, Meiryo, メイリオ, sans-serif;
  }

  p {
    font-size: 15px;
    line-height: 26px;
  }